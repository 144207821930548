import React, { useEffect, useState } from "react";
import Header from "./common/header";
import ArticleMedia from "./common/article_media";
import ArticleAnimationRight from "./common/article_animation_right";
// import OurWork from "./common/our_work";
import ArticleAnimationLeft from "./common/article_animation_left";
import Footer from "./common/footer";
// import ListWorking from "./common/list_working";
// import ArticleVideo from "./common/article_video";
// import ArticleLogo from "./common/article_logo";
import { db } from "../firebase-config";
import { collection, onSnapshot, doc, getDoc } from "@firebase/firestore";
import * as dataTable from "./constant_table";
import { TYPE_DETAIL } from "./constant";
// import ArticleService from "./common/article_service";
import SubmitForm from "./common/submit_form";
// import ArticleTitle from "./common/article_title";
// import ArticleVacancies from "./common/article_vacancies";
import { useParams, useNavigate } from "react-router-dom";

function Details() {
  const [detail, setDetail] = useState([]);
  const { id } = useParams();
  const [data, setData] = useState("");
  const [logo, setLogo] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getCompanyinfo();
    }
  }, [id]);

  const getCompanyinfo = async () => {
    const dbColection = collection(db, dataTable.TABLE_COMPANY);
    await onSnapshot(dbColection, (result) => {
      const com = result.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0];
      setData(com);
      setLogo(com.logo);
    });

    const docRef = doc(db, dataTable.TABLE_WORKING, id);

    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const docDetail = docSnap.data() || {};
      document.title = docDetail.title;
      setDetail(docDetail.detail || []);
    } else {
      navigate("/");
    }
  };

  return (
    <section>
      <Header backgroundColor={data.color} imgLogo={logo} data={data} />
      {detail.map((item, index) => {
        if (item.type === TYPE_DETAIL[0].value) {
          return (
            <ArticleMedia
              key={index}
              sub_title={item.sub_title}
              title={item.title}
              content={item.content}
              imageUrl={item.url}
            />
          );
        } else if (item.type === TYPE_DETAIL[1].value) {
          const newItem = { ...item, description: item.content };
          return <ArticleAnimationRight key={index} item={newItem} />;
        } else if (item.type === TYPE_DETAIL[2].value) {
          const newItem = { ...item, description: item.content };
          return <ArticleAnimationLeft key={index} item={newItem} />;
        }
        return <></>;
      })}

      <SubmitForm
        backgroundColor={data.color}
        title={"CONTACT US"}
        subTitle={"LET'S BE FRIENDS"}
      />

      <Footer backgroundColor={data.color} imgLogo={logo} data={data} />
    </section>
  );
}

export default Details;

import React, { useState, useEffect } from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";
import { db } from "../../firebase-config";
import {
  collection,
  onSnapshot,
  deleteDoc,
  doc,
  getDocs,
  where,
  query,
} from "@firebase/firestore";
import * as dataTable from "../constant_table";
import Loading from "../common/loading";
import { toast } from "react-toastify";
import Confirm from "./common/confirm";
import CreateWork from "./common/create_type_work";

function TypeWorking() {
  const [dataHome, setDataHome] = useState([]);
  const [dataEdit, setdataEdit] = useState({});
  const [openArticle, setOpenArticle] = useState(false);
  const dbColection = collection(db, dataTable.TABLE_TYPE_WORKKING);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [itemDelete, setItemDelete] = useState("");

  useEffect(() => {
    getCompanyinfo();
  }, []);

  const getCompanyinfo = async () => {
    await onSnapshot(dbColection, (result) => {
      const dbResult = result.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      dbResult.sort((a, b) => (a.index > b.index ? 1 : -1));
      setDataHome(dbResult);
      setLoading(false);
    });
  };

  const onAddNewArticle = () => {
    setOpenArticle(!openArticle);
    setdataEdit({});
  };

  const onEditArticle = (valueEdit) => {
    setdataEdit(valueEdit);
    setOpenArticle(true);
  };

  const onOpenConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const onConfirmDeleted = async (deletedItem) => {
    setItemDelete(deletedItem);
    onOpenConfirm();
  };

  const onDelete = async () => {
    onOpenConfirm();
    setLoading(true);

    const first = query(
      collection(db, dataTable.TABLE_WORKING),
      where("type", "==", itemDelete.code)
    );
    const documentSnapshots = await getDocs(first);

    if (documentSnapshots.size < 1) {
      const delDoc = doc(db, dataTable.TABLE_TYPE_WORKKING, itemDelete.id);
      await deleteDoc(delDoc).then(() => {
        toast.success("Deleted successfully!");
      });
    } else {
      toast.error("The type cannot be deleted. It exists in the list working!");
    }
    setLoading(false);
  };

  return (
    <section className="dashboard-admin">
      {(loading || loadingUpdate) && <Loading />}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Home" />
        <div className="container-fluid py-4">
          <div className="d-flex justify-content-end">
            <button
              style={{ border: "0px" }}
              className="justify-content-end badge badge-sm bg-gradient-primary p-2"
              onClick={() => onAddNewArticle()}
            >
              <span className="p-2">Add new</span>
            </button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-xxs font-weight-bold"></th>

                          <th className="text-uppercase text-xxs font-weight-bold">
                            Code
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Name
                          </th>
                          <th
                            style={{ width: "70px" }}
                            className="text-secondary opacity-7"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataHome.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ width: "20px" }}>
                                <div className="d-flex px-2 py-1">
                                  <div className="d-flex flex-column justify-content-center">
                                    <div className="mb-0 text-sm">
                                      {index + 1}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="align-middle">
                                <span>{item.code}</span>
                              </td>
                              <td className="align-middle">
                                <span>{item.name}</span>
                              </td>
                              <td className="align-middle justify-content-space-between">
                                <button
                                  className="btn-edit-list"
                                  onClick={() => onEditArticle(item)}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn-delete-list"
                                  style={{ marginLeft: "2px" }}
                                  onClick={() => onConfirmDeleted(item)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <CreateWork
        visible={openArticle}
        onClose={onAddNewArticle}
        setLoading={setLoadingUpdate}
        data={dataEdit}
        table_name={dataTable.TABLE_TYPE_WORKKING}
      />
      <Confirm
        visible={openConfirm}
        onClose={onOpenConfirm}
        header={"Delete Type"}
        title={"Are you sure you want to delete type?"}
        onConfirm={onDelete}
      />
    </section>
  );
}

export default TypeWorking;

import React, { useEffect, useState } from "react";
import { db } from "../../firebase-config";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import * as dataTable from "../constant_table";
import parse from "html-react-parser";
import { TABLE_TYPE_WORKKING } from "../constant";

function ListWorking({ backgroundColor, data = {} }) {
  const [datas, setDatas] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [translate3d, setTranslate3] = useState(0);

  const styleTransform = {
    transform: `translate3d(${translate3d}px, 0px, 0px)`,
    transition: `transform  .9s ease`,
  };

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    const getQuery = query(
      collection(db, dataTable.TABLE_WORKING),
      where("show", "==", true),
      where("table_type", "==", TABLE_TYPE_WORKKING)
    );

    await onSnapshot(getQuery, (result) => {
      setDatas(result.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
    onchangeIndexTap(0);
  };

  const onchangeIndexTap = (index) => {
    const divRootId = document.getElementById("root");
    let plusNum = 0;
    if (divRootId) {
      const withRoot = divRootId.offsetWidth;
      if (withRoot > 1024) {
        plusNum = withRoot * 0.2;
      } else {
        plusNum = withRoot * 0.09;
      }
    }

    const withMediaArticle = document.getElementById("m04_media_article");

    if (withMediaArticle) {
      const offsetWidth = withMediaArticle.offsetWidth;
      const numTrans = offsetWidth * index;
      setTranslate3(-numTrans + plusNum);
    } else {
      setTranslate3(plusNum);
    }

    setSelectedTab(index);
  };

  return (
    <article
      className="wp-block-luna-m04-service-types m04 break-out"
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="container m04__introduction">
        <h2 className="m04__heading uppercase heading--1">{data.title}</h2>
      </div>
      <div className="container m04__navigation">
        <div className="m04__select-wrapper">
          <select
            name="service-types"
            id="service-select"
            className="m04__select"
            defaultValue={selectedTab}
            onChange={(e) => onchangeIndexTap(e.target.value)}
          >
            {datas.map((item, index) => {
              return (
                <option value={index} key={index}>
                  {item.title}
                </option>
              );
            })}
          </select>
        </div>
        <div className="m04__list init-magic-line magic-line-mode-line">
          <div className="magic-line-inner">
            {datas.map((item, index) => {
              return (
                <button
                  type="button"
                  className={
                    selectedTab === index
                      ? "m04__nav-button uppercase heading--small active"
                      : "m04__nav-button uppercase heading--small"
                  }
                  key={index}
                  onClick={() => onchangeIndexTap(index)}
                >
                  {item.title}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      <div className="m04__wrapper">
        <div className="m04__container" style={styleTransform}>
          {datas.map((item, index) => {
            return (
              <article
                className={
                  index === selectedTab
                    ? "m04__slide is-selected"
                    : "m04__slide"
                }
                id="m04_media_article"
                key={index}
              >
                <figure className="m04__media">
                  <picture>
                    <source
                      srcSet={item.large_image}
                      media="(min-width: 768px)"
                    />
                    <source
                      srcSet={item.medium_image}
                      media="(min-width: 375px)"
                    />
                    <img
                      loading="lazy"
                      src={item.minium_image}
                      alt=""
                      width="1024"
                      height="1024"
                      className="m04__media__image"
                    />
                  </picture>
                </figure>
                <div className="m04__content">
                  <h3 className="m04__title uppercase heading--3">
                    {item.title}
                  </h3>
                  <p className="m04__text-introduction">
                    {parse(item.description)}
                  </p>
                  <p>
                    <a href={item.link}>{item.button_name}</a>
                  </p>
                </div>
              </article>
            );
          })}
        </div>
      </div>
      <div className="mobile-only container"></div>
    </article>
  );
}

export default ListWorking;

import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  setDoc,
  where,
  query,
} from "@firebase/firestore";
import { db } from "../../../firebase-config";
import Confirm from "./confirm";
import { removeAccents } from "../../helper";

export default function CreateTypeWork({
  visible,
  onClose,
  setLoading,
  data = {},
  table_name,
}) {
  const [openComfirm, setOpenComfirm] = useState(false);
  const [name, setName] = useState([]);
  const [code, setCode] = useState([]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setName(data.name);
      setCode(data.code);
    } else {
      setName("");
    }
  }, [data]);

  const onCreateHome = async () => {
    setOpenComfirm(false);

    if (!validateValue()) return;

    setLoading(true);
    if (Object.keys(data).length > 0) {
      const updDoc = doc(db, table_name, data.id);
      await setDoc(updDoc, { name: name }, { merge: true })
        .then(() => {
          toast.success("Update successfully!");
          onClose();
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const newFields = repareFields();
      const usersColection = collection(db, table_name);
      const first = query(
        collection(db, table_name),
        where("code", "==", code)
      );
      const documentSnapshots = await getDocs(first);
      if (documentSnapshots.size < 1) {
        await addDoc(usersColection, newFields).then(() => {
          toast.success("Create user successfully!");
          onClose();
        });
      } else {
        toast.error("The name is exist!");
      }

      setLoading(false);
    }
  };

  const validateValue = () => {
    if (name === "" || name === null || name === undefined) {
      toast.error("Type is required!");
      setOpenComfirm(false);
      return false;
    }

    return true;
  };

  const repareFields = () => {
    return {
      code: code,
      name: name,
    };
  };

  const onChangeOpemConform = () => {
    setOpenComfirm(!openComfirm);
  };

  const onChangeName = (value) => {
    setName(value);
    if (Object.keys(data).length < 1) {
      const lowerCase = value.toLowerCase();
      setCode(removeAccents(lowerCase.replaceAll(" ", "_")));
    }
  };

  return (
    <>
      <Modal size="lg" isOpen={visible} style={{ zIndex: "100" }}>
        <ModalHeader>{data.id ? "Edit article" : "Create article"}</ModalHeader>
        <ModalBody>
          <Row className="p-2">
            <Col xs={2}>
              <label>name:</label>
            </Col>
            <Col xs={10}>
              <input
                type="text"
                style={{ with: "98%", marginLeft: "15px" }}
                className={"form-control"}
                value={name}
                onChange={(e) => onChangeName(e.target.value)}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-small btn-light"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </button>
            <Button
              className="bg-gradient-primary"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => setOpenComfirm(true)}
            >
              Confirm
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Confirm
        visible={openComfirm}
        onClose={onChangeOpemConform}
        onConfirm={onCreateHome}
        header={data.id ? "Update type" : "Create type"}
        title={
          data.id
            ? "Are you sure you want to update type?"
            : "Are you sure you want to create type?"
        }
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { db } from "../../firebase-config";
import { collection, onSnapshot } from "@firebase/firestore";
import * as dataTable from "../constant_table";

function ArticleLogo() {
  const colectionDb = collection(db, dataTable.TABLE_LIST_LOGO);
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    getCompanyinfo();
  }, []);

  const getCompanyinfo = async () => {
    await onSnapshot(colectionDb, (result) => {
      setDatas(result.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {datas.map((data, index) => {
        return (
          <article
            className="wp-block-luna-m03-logo-slider m03 break-out"
            key={index}
          >
            <div className="container">
              <h3 className="m03__label uppercase heading--6">{data.name}</h3>
            </div>
            <Carousel
              autoPlay={true}
              responsive={responsive}
              infinite={true}
              arrows={false}
              draggable={true}
              className="m-2"
            >
              {data.url.map((item, iIndex) => {
                return (
                  <div
                    key={iIndex}
                    className="m03__slide"
                    style={{ minWidth: "300px", left: "0%" }}
                  >
                    <figure className="m03__media">
                      <img
                        draggable="false"
                        src={item}
                        alt="/"
                        width="300"
                        height="169"
                        className="m03__media__image"
                      />
                    </figure>
                  </div>
                );
              })}
            </Carousel>
          </article>
        );
      })}
    </>
  );
}

export default ArticleLogo;

import React from "react";
import parse from "html-react-parser";

function ArticleAnimationRight({ item = {} }) {
  return (
    <section>
      <article className="wp-block-luna-m05-image-and-text m05 break-out m05--left">
        <div className="container m05__container">
          <div className="m05__content">
            {item.title && (
              <span className="m02__label heading--2">{item.title}</span>
            )}
            <h2 className="m05__heading uppercase heading--2">
              {item.sub_title}
            </h2>
            <div className="m05__introduction">{parse(item.description)}</div>
            {item.link && (
              <div className="m02__button-wrap">
                <a href={item.link} className="m02__button button">
                  {item.button_name}
                  <svg
                    className="svg-icon svg-icon--icon_arrow"
                    role="img"
                    aria-hidden="true"
                  >
                    <use href="#sprite-icon_arrow"></use>
                  </svg>
                </a>
              </div>
            )}
          </div>
          <figure className="m05__media">
            <img
              src={item.url}
              alt=""
              width="988"
              height="828"
              className="m05__media__image"
            />
          </figure>
        </div>
      </article>
    </section>
  );
}

export default ArticleAnimationRight;

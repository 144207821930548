import React, { useState, useEffect } from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";
import { db } from "../../firebase-config";
import { collection, getDocs, updateDoc, doc } from "@firebase/firestore";
import * as dataTable from "../constant_table";
import Loading from "../common/loading";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import * as Icon from "react-feather";
import Confirm from "./common/confirm";
import SelectImage from "./common/select_images";

function ListLogo() {
  document.title = "List Logo Partner";
  const [id, setID] = useState("");
  const [name, setName] = useState("");
  const [listImages, setListImages] = useState([]);
  const dbColection = collection(db, dataTable.TABLE_LIST_LOGO);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openSelectImg, setOpenSelectImg] = useState(false);
  const [indexSelect, setIndexSelect] = useState("");

  useEffect(() => {
    getCompanyinfo();
  }, []);

  const getCompanyinfo = async () => {
    const querySnapshot = await getDocs(dbColection);
    const data =
      querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))[0] || {};
    setName(data.name);
    setID(data.id);
    setListImages(data.url || []);
    setLoading(false);
  };

  const onChangeValue = (value) => {
    setName(value);
  };

  const onAddNewArticle = () => {
    const list = [...listImages, ""];
    setListImages(list);
  };

  const onOpenSelectImg = () => {
    setOpenSelectImg(!openSelectImg);
  };

  const onChangeSelectImage = (index) => {
    setIndexSelect(index);
    onOpenSelectImg();
  };

  const onChangeImage = (image) => {
    setLoading(true);
    const list = listImages;
    list[indexSelect] = image;
    setListImages([...list]);
    onOpenSelectImg();
    setLoading(false);
  };

  const onOpenConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const onSave = async () => {
    setLoadingUpdate(true);
    onOpenConfirm();
    const logoDoc = doc(db, dataTable.TABLE_LIST_LOGO, id);
    const newFields = {
      name: name,
      url: listImages.filter((x) => x !== ""),
    };

    await updateDoc(logoDoc, newFields)
      .then(() => {
        toast.success("Update successfully!");
        getCompanyinfo();
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setLoadingUpdate(false);
      });
  };

  const onRemove = (index) => {
    setLoading(true);
    const list = [...listImages];
    list.splice(index, 1);
    setListImages(list);
    setLoading(false);
  };

  return (
    <section className="dashboard-admin">
      {(loading || loadingUpdate) && <Loading />}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Services" />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0" style={{ margin: "20px" }}>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Name:</label>
                    </Col>
                    <Col xs={10}>
                      <input
                        type="text"
                        style={{ with: "98%", marginLeft: "15px" }}
                        className={"form-control"}
                        value={name}
                        onChange={(e) => onChangeValue(e.target.value)}
                      />
                    </Col>
                  </Row>

                  {listImages.map((value, index) => {
                    return (
                      <Row className="p-2" key={index}>
                        <Col xs={2}>
                          <label>Url Image {index + 1}:</label>
                        </Col>
                        <Col xs={10} style={{ display: "flex" }}>
                          <input
                            type="text"
                            style={{ with: "98%", marginLeft: "15px" }}
                            className={"form-control"}
                            value={value}
                          />
                          <div style={{ width: "50px", height: "35px" }}>
                            <img src={value} alt={value} />
                          </div>
                          <button
                            className="btn-edit"
                            onClick={() => onChangeSelectImage(index)}
                          >
                            Edit
                          </button>

                          <button
                            className="btn-x-remove"
                            onClick={() => onRemove(index)}
                          >
                            x
                          </button>
                        </Col>
                      </Row>
                    );
                  })}
                  <div className="d-flex justify-content-end">
                    <button
                      style={{ border: "0px" }}
                      className="justify-content-end badge badge-sm bg-gradient-primary p-2 m-2"
                      onClick={() => onOpenConfirm()}
                    >
                      <span className="p-2">Save</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            style={{ border: "0px" }}
            className="badge badge-sm bg-gradient-success"
            onClick={onAddNewArticle}
          >
            <Icon.Plus />
          </button>
        </div>
      </main>
      <Confirm
        visible={openConfirm}
        onClose={onOpenConfirm}
        onConfirm={onSave}
        header={"Update list logo"}
        title={"Are you sure you want to update infor?"}
      />
      <SelectImage
        visible={openSelectImg}
        onClose={onOpenSelectImg}
        onSave={onChangeImage}
      />
    </section>
  );
}

export default ListLogo;

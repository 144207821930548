import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { db } from "../../../firebase-config";
import { collection, query, where, limit, getDocs } from "@firebase/firestore";
import * as dataTable from "../../constant_table";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { ROLE_NAV_ADM_HR, ROLE_NAV_ADM_MAR } from "../../constant";

function NavBar() {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const [role, setRole] = useState("");
  const [cookies, setCookie] = useCookies(["token"]);
  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    if (cookies.email) {
      const first = query(
        collection(db, dataTable.TABLE_USERS),
        where("email", "==", cookies.email),
        // where("token", "==", cookies.token),
        limit(1)
      );

      const documentSnapshots = await getDocs(first);

      documentSnapshots.forEach((result) => {
        const dbResult = result.data();
        if (dbResult) {
          setRole(dbResult.role);
        } else {
          clearCookie();
        }
      });
    } else {
      clearCookie();
    }
  };

  const clearCookie = () => {
    setRole("");
    setCookie("token", "", { path: "/" });
    setCookie("email", "", { path: "/" });
    navigate("/admin/login");
  };

  const hideNav = () => {
    const sidenav = document.getElementById("sidenav-main");
    sidenav.classList.add("nav-xl-none");
  };

  return (
    <aside
      className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark nav-xl-none"
      id="sidenav-main"
    >
      <div className="sidenav-header">
        <span
          className="cursor-pointer text-white d-xl-none position-absolute end-0 top-0 opacity-5 p-2"
          onClick={() => hideNav()}
        >
          X
        </span>
        <a
          className="m-3 d-flex justify-content-center text-decoration-none"
          href="/admin/dashboard"
        >
          <span className="ms-1 font-weight-bold text-white">Target Media</span>
        </a>
      </div>
      <hr className="horizontal light mt-0 mb-2" />
      <div
        className="collapse navbar-collapse w-auto"
        id="sidenav-collapse-main"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className={
                pathname === "/admin/dashboard"
                  ? "nav-link text-white active bg-gradient-primary"
                  : "nav-link text-white "
              }
              href="/admin/dashboard"
            >
              <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <Icon.Grid />
              </div>
              <span className="nav-link-text ms-1">Dashboard</span>
            </a>
          </li>

          {ROLE_NAV_ADM_HR.includes(role) && (
            <li className="nav-item">
              <a
                className={
                  pathname === "/admin/users"
                    ? "nav-link text-white active bg-gradient-primary"
                    : "nav-link text-white "
                }
                href="/admin/users"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <Icon.Users />
                </div>
                <span className="nav-link-text ms-1">Users</span>
              </a>
            </li>
          )}
          {ROLE_NAV_ADM_MAR.includes(role) && (
            <li className="nav-item">
              <a
                className={
                  pathname === "/admin/home"
                    ? "nav-link text-white active bg-gradient-primary"
                    : "nav-link text-white "
                }
                href="/admin/home"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <Icon.Home />
                </div>
                <span className="nav-link-text ms-1">Home</span>
              </a>
            </li>
          )}
          {ROLE_NAV_ADM_MAR.includes(role) && (
            <li className="nav-item">
              <a
                className={
                  pathname === "/admin/services"
                    ? "nav-link text-white active bg-gradient-primary"
                    : "nav-link text-white "
                }
                href="/admin/services"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <Icon.Server />
                </div>
                <span className="nav-link-text ms-1">Service</span>
              </a>
            </li>
          )}
          {ROLE_NAV_ADM_MAR.includes(role) && (
            <li className="nav-item">
              <a
                className={
                  pathname === "/admin/projects"
                    ? "nav-link text-white active bg-gradient-primary"
                    : "nav-link text-white "
                }
                href="/admin/projects"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <Icon.Command />
                </div>
                <span className="nav-link-text ms-1">Project</span>
              </a>
            </li>
          )}
          {ROLE_NAV_ADM_MAR.includes(role) && (
            <li className="nav-item">
              <a
                className={
                  pathname === "/admin/culture_careers"
                    ? "nav-link text-white active bg-gradient-primary"
                    : "nav-link text-white "
                }
                href="/admin/culture_careers"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <Icon.Shield />
                </div>
                <span className="nav-link-text ms-1">Culture careers</span>
              </a>
            </li>
          )}
          {ROLE_NAV_ADM_MAR.includes(role) && (
            <li className="nav-item">
              <a
                className={
                  pathname === "/admin/list-logo"
                    ? "nav-link text-white active bg-gradient-primary"
                    : "nav-link text-white "
                }
                href="/admin/list-logo"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <Icon.List />
                </div>
                <span className="nav-link-text ms-1">List Logo Partner</span>
              </a>
            </li>
          )}
          {ROLE_NAV_ADM_MAR.includes(role) && (
            <li className="nav-item">
              <a
                className={
                  pathname === "/admin/list-our-work"
                    ? "nav-link text-white active bg-gradient-primary"
                    : "nav-link text-white "
                }
                href="/admin/list-our-work"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <Icon.Briefcase />
                </div>
                <span className="nav-link-text ms-1">List Our Work</span>
              </a>
            </li>
          )}
          {ROLE_NAV_ADM_MAR.includes(role) && (
            <li className="nav-item">
              <a
                className={
                  pathname === "/admin/type-working"
                    ? "nav-link text-white active bg-gradient-primary"
                    : "nav-link text-white "
                }
                href="/admin/type-working"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <Icon.Table />
                </div>
                <span className="nav-link-text ms-1">Type Working</span>
              </a>
            </li>
          )}
          {ROLE_NAV_ADM_MAR.includes(role) && (
            <li className="nav-item">
              <a
                className={
                  pathname === "/admin/list-working"
                    ? "nav-link text-white active bg-gradient-primary"
                    : "nav-link text-white "
                }
                href="/admin/list-working"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <Icon.Book />
                </div>
                <span className="nav-link-text ms-1">List Working</span>
              </a>
            </li>
          )}
          {ROLE_NAV_ADM_HR.includes(role) && (
            <li className="nav-item">
              <a
                className={
                  pathname === "/admin/vacancies"
                    ? "nav-link text-white active bg-gradient-primary"
                    : "nav-link text-white "
                }
                href="/admin/vacancies"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <Icon.Twitch />
                </div>
                <span className="nav-link-text ms-1">Vacancies</span>
              </a>
            </li>
          )}
          {ROLE_NAV_ADM_MAR.includes(role) && (
            <li className="nav-item">
              <a
                className={
                  pathname === "/admin/setting"
                    ? "nav-link text-white active bg-gradient-primary"
                    : "nav-link text-white "
                }
                href="/admin/setting"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <Icon.Settings />
                </div>
                <span className="nav-link-text ms-1">Setting</span>
              </a>
            </li>
          )}
          {ROLE_NAV_ADM_MAR.includes(role) && (
            <li className="nav-item">
              <a
                className={
                  pathname === "/admin/upload-images"
                    ? "nav-link text-white active bg-gradient-primary"
                    : "nav-link text-white "
                }
                href="/admin/upload-images"
              >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                  <Icon.Settings />
                </div>
                <span className="nav-link-text ms-1">Upload Images</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </aside>
  );
}

export default NavBar;

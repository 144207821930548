import React, { useState, useEffect } from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";
import { toast } from "react-toastify";
// import Loading from "../common/loading";
import Confirm from "./common/confirm";
import { Row } from "reactstrap";
import { convertBase64 } from "../helper";

function UploadImages() {
  // const [loading, setLoading] = useState(true);
  const [dataImages, setDataImages] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deletePath, setDeletePath] = useState("");
  const [newParam, setNewParam] = useState("");
  console.log(dataImages);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await fetch("/file-images.php").then((response) =>
      response.json()
    );
    if (res.success) {
      setDataImages(res.data || []);
    }
  };

  const onOpenConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const onDelete = async (name) => {
    setDeletePath(name);
    onOpenConfirm();
  };

  const submitDeleted = async () => {
    const res = await fetch("/delete-file.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "file_name=" + deletePath,
    }).then((response) => response.json());
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    setDataImages("");
    getData();
    onOpenConfirm();
  };

  const convertImage = async (file) => {
    const base64 = await convertBase64(file);
    setNewParam(base64);
  };

  const onUploadImages = async () => {
    if (!newParam) {
      toast.error("No files selected");
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: new URLSearchParams({ image_base64: newParam }),
    };
    const res = await fetch("/upload-img.php", requestOptions).then(
      (response) => response.json()
    );

    if (res.success) {
      toast.success(res.message);
      setNewParam("");
    } else {
      toast.error(res.message);
    }
    getData();
  };

  return (
    <section className="dashboard-admin">
      {/* {loading && <Loading />} */}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Home" />
        <div className="container-fluid py-4">
          <div className="card card-body">
            <Row className="p-2">
              <input
                type={"file"}
                accept="image/*"
                onChange={(e) => convertImage(e.target.files[0])}
              />
              <hr />
              <div style={{ width: "250px" }}>
                <img
                  src={newParam}
                  alt="images"
                  style={{ width: "100%", border: "1px solid #e1dfd5" }}
                />
              </div>
              <br />
              <button
                className="btn btn-small btn-primary mt-2"
                onClick={() => {
                  onUploadImages();
                }}
              >
                Upload
              </button>
            </Row>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0 pb-2">
                  <div>
                    {dataImages.map((value, index) => {
                      return (
                        <div className="select-image-div-3" key={index}>
                          <div
                            style={{ display: "flex", position: "absolute" }}
                          >
                            <div
                              style={{
                                width: "26px",
                                borderRadius: "10px",
                                textAlign: "center",
                                border: "1px solid #dee2e6",
                                cursor: "pointer",
                                color: "#dee2e6",
                              }}
                              onClick={() => onDelete(value)}
                            >
                              x
                            </div>
                          </div>
                          <div className="bg-select-image-div-2">
                            <img
                              src={"/" + value}
                              key={index}
                              className="select-image-bg"
                              alt={value}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Confirm
        visible={openConfirm}
        onClose={onOpenConfirm}
        header={"Delete Article"}
        title={"Are you sure you want to delete article?"}
        onConfirm={submitDeleted}
      />
    </section>
  );
}

export default UploadImages;

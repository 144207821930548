import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import { DEFAULTE_ROLE } from "../constant";
import { phoneValidation, emailValidation } from "../helper";
import { collection, addDoc, doc, updateDoc } from "@firebase/firestore";
import { db } from "../../firebase-config";
import * as tableUser from "../constant_table";
toast.configure();

export default function CreateUser(props) {
  const { visible, onClose, data, setLoading } = props;
  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [active, setActive] = useState(false);
  const current = new Date();
  const usersColection = collection(db, tableUser.TABLE_USERS);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setUserName(data.name);
      setPhone(data.phone);
      setEmail(data.email);
      setRole(data.role);
      setActive(data.active);
    } else {
      setUserName("");
      setPhone("");
      setEmail("");
      setRole("");
      setActive(false);
    }
  }, [visible]);

  const onSaveUser = async () => {
    if (userName === "" || userName === null) {
      return toast.error("Name is required!");
    }
    if (phone === "" || phone === null) {
      return toast.error("Phone is required!");
    }
    if (!phoneValidation(phone)) {
      return toast.error("Phone is not correct format!");
    }
    if (email === "" || email === null) {
      return toast.error("Email is required!");
    }
    if (!emailValidation(email)) {
      return toast.error("Email is not correct format!");
    }
    if (role === "" || role === null) {
      return toast.error("Role is required!");
    }
    setLoading(true);

    if (Object.keys(data).length > 0) {
      const userDoc = doc(db, tableUser.TABLE_USERS, data.id);
      const newFields = dataUpdata();
      if (Object.keys(newFields).length > 0) {
        await updateDoc(userDoc, newFields)
          .then(() => {
            toast.success("Update user successfully!");
            onClose();
          })
          .catch((error) => {
            toast.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        onClose();
      }
    } else {
      const data = {
        name: userName,
        phone: phone,
        email: email,
        active: active,
        role: role,
        password: "c4ca4238a0b923820dcc509a6f75849b",
        created_date: current,
      };

      await addDoc(usersColection, data)
        .then(() => {
          toast.success("Create user successfully!");
          onClose();
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const dataUpdata = () => {
    const updateFields = {};
    if (data.name !== userName) {
      updateFields.name = userName;
    }
    if (data.phone !== phone) {
      updateFields.phone = phone;
    }
    if (data.email !== email) {
      updateFields.email = email;
    }
    if (data.role !== role) {
      updateFields.role = role;
    }
    if (data.active !== active) {
      updateFields.active = active;
    }
    return updateFields;
  };

  return (
    <Modal size="lg" isOpen={visible}>
      <ModalHeader>{data ? "User info" : "Create user"} </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={3}>
            <label>User Name:</label>
          </Col>
          <Col xs={9}>
            <input
              className={
                !userName ? "form-control input-error" : "form-control"
              }
              type="text"
              value={userName}
              maxLength="150"
              onChange={(e) => setUserName(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={3}>
            <label>Phone:</label>
          </Col>
          <Col xs={9}>
            <input
              type="text"
              className={!phone ? "form-control input-error" : "form-control"}
              value={phone}
              maxLength="10"
              onChange={(e) => setPhone(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={3}>
            <label>Email:</label>
          </Col>
          <Col xs={9}>
            <input
              type="text"
              className={!email ? "form-control input-error" : "form-control"}
              value={email}
              maxLength="100"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={3}>
            <label>Role:</label>
          </Col>
          <Col xs={9}>
            <select
              value={role}
              className={!role ? "form-control input-error" : "form-control"}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="">--Select role--</option>
              {DEFAULTE_ROLE.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={3}>
            <label>Active:</label>
          </Col>
          <Col xs={9}>
            <input
              style={{ with: "4rem" }}
              type="checkbox"
              checked={active}
              onChange={() => {
                setActive(!active);
              }}
              createRef
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-small btn-light"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
          <Button
            className="bg-gradient-primary"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => onSaveUser()}
          >
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

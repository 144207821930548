import React, { useState, useEffect } from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";
import { Col, Row } from "reactstrap";
import { db } from "../../firebase-config";
import { collection, updateDoc, doc, onSnapshot } from "@firebase/firestore";
import * as dataTable from "../constant_table";
import Loading from "../common/loading";
import Confirm from "./common/confirm";
import { toast } from "react-toastify";
import SelectImage from "./common/select_images";

function Setting() {
  document.title = "Setting";
  const companyColection = collection(db, dataTable.TABLE_COMPANY);
  const [openComfirm, setOpenComfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [description, setDescription] = useState("");
  const [color, setColor] = useState("");
  const [id, setId] = useState("");
  const [copyright, setCopyright] = useState("");
  const [icon, setIcon] = useState("");
  const [image, setImage] = useState("");
  const [logo, setLogo] = useState("");
  const [url, setUrl] = useState("");
  const [openSelectImage, setOpenSelectImage] = useState(false);
  const [fieldUpdate, setFieldUpdate] = useState("");

  useEffect(() => {
    getCompanyinfo();
  }, [id]);

  const getCompanyinfo = async () => {
    await onSnapshot(companyColection, (result) => {
      const cop = result.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0];
      setId(cop.id);
      setCompanyName(cop.company_name);
      setDescription(cop.description);
      setColor(cop.color);
      setCopyright(cop.copyright);
      setIcon(cop.icon);
      setImage(cop.image);
      setLogo(cop.logo);
      setUrl(cop.url);
      setLoadingList(false);
    });
  };
  const onChangePopup = async () => {
    setOpenComfirm(!openComfirm);
  };

  const onUpdate = async () => {
    onChangePopup();
    setLoading(true);
    const settingDoc = doc(db, dataTable.TABLE_COMPANY, id);
    const newFields = {
      company_name: companyName,
      description: description,
      color: color,
      copyright: copyright,
      icon: icon,
      image: image,
      logo: logo,
      url: url,
    };

    await updateDoc(settingDoc, newFields)
      .then(() => {
        toast.success("Update successfully!");
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onOpenSelectImage = (fieldName) => {
    setFieldUpdate(fieldName);
    setOpenSelectImage(!openSelectImage);
  };

  const onChangeImg = (value) => {
    if (fieldUpdate === "logo") {
      setLogo(value);
    } else if (fieldUpdate === "image") {
      setImage(value);
    } else if (fieldUpdate === "icon") {
      setIcon(value);
    }
    setOpenSelectImage(!openSelectImage);
  };

  return (
    <section className="dashboard-admin">
      {(loading || loadingList) && <Loading />}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Setting" />
        <div className="container-fluid py-4">
          <div className="d-flex justify-content-end">
            <button
              style={{ border: "0px" }}
              className="justify-content-end badge badge-sm bg-gradient-primary p-2"
              onClick={() => onChangePopup()}
            >
              <span className="p-2">Save</span>
            </button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0" style={{ margin: "20px" }}>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Company Name:</label>
                    </Col>
                    <Col xs={10}>
                      <input
                        type="text"
                        style={{ with: "98%", marginLeft: "15px" }}
                        className={"form-control"}
                        value={companyName}
                        maxLength="100"
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Description Website:</label>
                    </Col>
                    <Col xs={10}>
                      <textarea
                        type="text"
                        style={{ with: "98%", marginLeft: "15px" }}
                        className={"form-control"}
                        value={description}
                        maxLength="100"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Color Website:</label>
                    </Col>
                    <Col xs={10} style={{ display: "flex" }}>
                      <input
                        type="text"
                        style={{ maxWidth: "200px", marginLeft: "15px" }}
                        className={"form-control"}
                        value={color}
                        maxLength="100"
                        onChange={(e) => setColor(e.target.value)}
                      />
                      <input
                        type="color"
                        style={{
                          width: "100px",
                          marginLeft: "15px",
                          alignSelf: "center",
                        }}
                        className={"form-control"}
                        value={color}
                        maxLength="100"
                        onChange={(e) => setColor(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Copyright:</label>
                    </Col>
                    <Col xs={10}>
                      <input
                        type="text"
                        style={{ with: "98%", marginLeft: "15px" }}
                        className={"form-control"}
                        value={copyright}
                        maxLength="100"
                        onChange={(e) => setCopyright(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Icon:</label>
                    </Col>
                    <Col xs={10} style={{ display: "flex" }}>
                      <input
                        type="text"
                        style={{
                          with: "98%",
                          marginLeft: "15px",
                          padding: "9px",
                        }}
                        className={"form-control"}
                        defaultValue={icon}
                        disabled
                      />
                      <button
                        className="btn-edit"
                        onClick={() => onOpenSelectImage("icon")}
                      >
                        Edit
                      </button>
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Image:</label>
                    </Col>
                    <Col xs={10} style={{ display: "flex" }}>
                      <input
                        type="text"
                        style={{
                          with: "98%",
                          marginLeft: "15px",
                          padding: "9px",
                        }}
                        className={"form-control"}
                        defaultValue={image}
                        disabled
                      />
                      <button
                        className="btn-edit"
                        onClick={() => onOpenSelectImage("image")}
                      >
                        Edit
                      </button>
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Logo:</label>
                    </Col>
                    <Col xs={10} style={{ display: "flex" }}>
                      <input
                        type="text"
                        style={{
                          with: "98%",
                          marginLeft: "15px",
                          padding: "9px",
                        }}
                        className={"form-control"}
                        defaultValue={logo}
                        disabled
                      />
                      <button
                        className="btn-edit"
                        onClick={() => onOpenSelectImage("logo")}
                      >
                        Edit
                      </button>
                    </Col>
                  </Row>
                  <Row className="p-2">
                    <Col xs={2}>
                      <label>Url:</label>
                    </Col>
                    <Col xs={10}>
                      <input
                        type="text"
                        style={{ with: "98%", marginLeft: "15px" }}
                        className={"form-control"}
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Confirm
          visible={openComfirm}
          onClose={onChangePopup}
          onConfirm={onUpdate}
          header={"Update setting"}
          title={"Are you sure you want to update infor?"}
        />
        <SelectImage
          visible={openSelectImage}
          onClose={onOpenSelectImage}
          onSave={onChangeImg}
        />
      </main>
    </section>
  );
}

export default Setting;

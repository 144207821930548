import React, { useState, useEffect } from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";
import { db } from "../../firebase-config";
import { collection, deleteDoc, doc, onSnapshot } from "@firebase/firestore";
import Loading from "../common/loading";
import CreateUser from "./create_user";
import { toast } from "react-toastify";
import Confirm from "./common/confirm";
import * as tableUser from "../constant_table";

function Users() {
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(true);
  const [userIdDelete, setUserIdDelete] = useState("");
  const [userEdit, setUserEdit] = useState({});
  const usersColection = collection(db, tableUser.TABLE_USERS);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    await onSnapshot(usersColection, (result) => {
      setUsers(result.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setLoadingList(false);
    });
  };

  const onOpenModel = () => {
    setOpenModal(!openModal);
  };

  const onCloseEdit = () => {
    onOpenModel();
    setUserEdit({});
  };

  const onEdit = (id) => {
    onOpenModel();
    const detail = users.find((x) => x.id === id);
    setUserEdit(detail);
  };

  const onDelete = async () => {
    onOpenConfirm();
    setLoading(true);
    const userDoc = doc(db, tableUser.TABLE_USERS, userIdDelete);
    await deleteDoc(userDoc)
      .then(() => {
        toast.success("Deleted successfully!");
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onOpenConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const onSetDeletedUser = async (id) => {
    setUserIdDelete(id);
    onOpenConfirm();
  };
  return (
    <section className="dashboard-admin">
      {(loading || loadingList) && <Loading />}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Users" />
        <div className="container-fluid py-4">
          <div className="d-flex justify-content-end">
            <button
              style={{ border: "0px" }}
              className="justify-content-end badge badge-sm bg-gradient-primary p-2"
              onClick={() => onOpenModel()}
            >
              <span className="p-2">Create User</span>
            </button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Author
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Function
                          </th>
                          <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Status
                          </th>
                          <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Employed
                          </th>
                          <th
                            style={{ width: "70px" }}
                            className="text-secondary opacity-7"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((item, index) => {
                          const sec = item.created_date.seconds;
                          const time = new Date(sec * 1000).toLocaleDateString(
                            "vi-VN"
                          );
                          return (
                            <tr key={index}>
                              <td>
                                <div className="d-flex px-2 py-1">
                                  <div className="d-flex flex-column justify-content-center">
                                    <div className="mb-0 text-sm">
                                      {item.name}
                                    </div>
                                    <p className="text-xs text-secondary mb-0">
                                      {item.phone}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="text-xs font-weight-bold mb-0">
                                  {item.role}
                                </p>
                                <p className="text-xs text-secondary mb-0">
                                  {item.phone}
                                </p>
                              </td>
                              <td className="align-middle text-center text-sm">
                                <span
                                  className={
                                    item.active
                                      ? "badge badge-sm bg-gradient-success"
                                      : "badge badge-sm bg-gradient-secondary"
                                  }
                                >
                                  {item.active ? "Online" : "Offline"}
                                </span>
                              </td>
                              <td className="align-middle text-center">
                                <span className="text-secondary text-xs font-weight-bold">
                                  {time}
                                </span>
                              </td>
                              <td className="align-middle justify-content-space-between">
                                <button
                                  className="btn-edit-list"
                                  onClick={() => onEdit(item.id)}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn-delete-list"
                                  style={{ marginLeft: "2px" }}
                                  onClick={() => onSetDeletedUser(item.id)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateUser
          visible={openModal}
          onClose={onCloseEdit}
          setLoading={setLoading}
          data={userEdit}
        />
        <Confirm
          visible={openConfirm}
          onClose={onOpenConfirm}
          onConfirm={onDelete}
          header={"Delete user"}
          title={"Are you sure you want to delete user?"}
        />
      </main>
    </section>
  );
}

export default Users;

export const TABLE_USERS = "users";
export const TABLE_COMPANY = "company_information";
export const TABLE_HOME = "home_page";
export const TABLE_SERVICES = "servies_page";
export const TABLE_LIST_LOGO = "logo_list";
export const TABLE_PROJECT = "project_page";
export const TABLE_CULTURE_CAREERS = "culture_careers";
export const TABLE_WORKING = "list_working";
export const TABLE_OUR_WORK = "our_work";
export const TABLE_TYPE_WORKKING = "type_working";
export const TABLE_KEY_CODE = "list_key_code";

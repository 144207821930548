import React, { useEffect, useState } from "react";

function Header({ backgroundColor, imgLogo, data = {} }) {
  const [offset, setOffset] = useState(0);
  const pathname = window.location.pathname;

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    document.getElementsByTagName("META")["description"].content =
      data.description ||
      "Target Media has embarked on a journey to provide OOH solutions. Over the past 13 years, we have established great partnerships with major brands in Vietnam as well as a network of partners. With us, your brand value becomes more vivid in the minds of target customers.";
    document.getElementsByTagName("META")["title"].content = data.name;
    document.getElementsByTagName("META")["image"].content = data.image;
    document.getElementsByTagName("LINK")["icon"].href = data.icon;
    document.getElementsByTagName("LINK")["canonical"].href = data.url;
  }, [data]);

  const onShowMenuMobile = () => {
    const menu = document.getElementById("menu-wrapper-mb");
    const containsMenuMb = menu.classList.contains("menu-mb");
    if (containsMenuMb) {
      menu.classList.remove("menu-mb");
    } else {
      menu.classList.add("menu-mb");
    }
  };
  return (
    <section>
      <header
        className="g01 is-scrolled"
        role="banner"
        style={
          offset > 50
            ? {
                position: "fixed",
                maxHeight: "4rem",
                backgroundColor: backgroundColor,
              }
            : { backgroundColor: backgroundColor }
        }
      >
        <a href="/" className="g01__logo">
          <img src={imgLogo} alt={imgLogo} className="g01__logo-image" />
        </a>
        <button
          type="button"
          className="g01__hamburger"
          onClick={() => onShowMenuMobile()}
        >
          <span className="screen-reader-text">Toggle main menu</span>
        </button>

        <div
          className="g01__menu-wrapper"
          id="menu-wrapper-mb"
          style={{
            // backgroundColor: "#ff0037 !important",
            backgroundColor: backgroundColor,
          }}
        >
          <nav className="g01__primary">
            <ul id="primary-menu" className="g01__primary-menu">
              <li
                className={
                  pathname === "/"
                    ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item menu-item-home"
                    : "menu-item menu-item-type-post_type menu-item-object-page menu-item-home"
                }
              >
                <a title="home" href="/">
                  Home
                </a>
              </li>
              <li
                className={
                  pathname === "/services"
                    ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item"
                    : "menu-item menu-item-type-post_type menu-item-object-page "
                }
              >
                <a title="services" href="/services">
                  Services
                </a>
              </li>
              <li
                className={
                  pathname === "/projects"
                    ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item"
                    : "menu-item menu-item-type-post_type menu-item-object-page "
                }
              >
                <a title="projects" href="/projects">
                  Projects
                </a>
              </li>
              <li
                className={
                  pathname === "/culture_careers"
                    ? "menu-item menu-item-type-post_type menu-item-object-page current-menu-item"
                    : "menu-item menu-item-type-post_type menu-item-object-page "
                }
              >
                {/* <a title="culture careers" href="/culture_careers">
                  Culture & Careers
                </a> */}
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </section>
  );
}

export default Header;

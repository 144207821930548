import React, { useState, useEffect } from "react";
import "./material-dashboard.css";
import NavBar from "./common/navbar";
import Header from "./common/header";
import { db } from "../../firebase-config";
import { collection, onSnapshot, deleteDoc, doc } from "@firebase/firestore";
import * as dataTable from "../constant_table";
import Loading from "../common/loading";
import CreateArticle from "./common/create_article";
import { TYPE_ARTICLE } from "../constant";
import Confirm from "./common/confirm";
import { toast } from "react-toastify";

function Home() {
  const [dataHome, setDataHome] = useState([]);
  const [dataEdit, setdataEdit] = useState({});
  const [openArticle, setOpenArticle] = useState(false);
  const companyColection = collection(db, dataTable.TABLE_HOME);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  useEffect(() => {
    getCompanyinfo();
  }, []);

  const getCompanyinfo = async () => {
    await onSnapshot(companyColection, (result) => {
      const dbResult = result.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      dbResult.sort((a, b) => (a.index > b.index ? 1 : -1));
      setDataHome(dbResult);
      setLoading(false);
    });
  };

  const onAddNewArticle = () => {
    setOpenArticle(!openArticle);
    setdataEdit({});
  };

  const onEditArticle = (valueEdit) => {
    setdataEdit(valueEdit);
    setOpenArticle(true);
  };

  const onOpenConfirm = () => {
    setOpenConfirm(!openConfirm);
  };

  const onConfirmDeleted = async (id) => {
    setDeleteID(id);
    onOpenConfirm();
  };

  const onDelete = async () => {
    onOpenConfirm();
    setLoading(true);
    const delDoc = doc(db, dataTable.TABLE_HOME, deleteID);
    await deleteDoc(delDoc)
      .then(() => {
        toast.success("Deleted successfully!");
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className="dashboard-admin">
      {(loading || loadingUpdate) && <Loading />}
      <NavBar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Header title="Home" />
        <div className="container-fluid py-4">
          <div className="d-flex justify-content-end">
            <button
              style={{ border: "0px" }}
              className="justify-content-end badge badge-sm bg-gradient-primary p-2"
              onClick={() => onAddNewArticle()}
            >
              <span className="p-2">Add new</span>
            </button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Type
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold">
                            Title
                          </th>
                          <th className="text-uppercase text-xxs font-weight-bold text-center">
                            Index
                          </th>
                          <th
                            style={{ width: "70px" }}
                            className="text-secondary opacity-7"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataHome.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="align-middle">
                                <span>
                                  {
                                    TYPE_ARTICLE.find(
                                      (x) => x.value === item.type
                                    ).label
                                  }
                                </span>
                              </td>
                              <td className="align-middle">
                                <span>{item.title}</span>
                              </td>
                              <td className="align-middle text-center ">
                                <span>{item.index}</span>
                              </td>
                              <td className="align-middle justify-content-space-between">
                                <button
                                  className="btn-edit-list"
                                  onClick={() => onEditArticle(item)}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn-delete-list"
                                  style={{ marginLeft: "2px" }}
                                  onClick={() => onConfirmDeleted(item.id)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <CreateArticle
        visible={openArticle}
        onClose={onAddNewArticle}
        setLoading={setLoadingUpdate}
        data={dataEdit}
        table_name={dataTable.TABLE_HOME}
      />
      <Confirm
        visible={openConfirm}
        onClose={onOpenConfirm}
        header={"Delete Article"}
        title={"Are you sure you want to delete article?"}
        onConfirm={onDelete}
      />
    </section>
  );
}

export default Home;

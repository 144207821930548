import React, { useEffect, useState } from "react";
import Header from "./common/header";
import ArticleMedia from "./common/article_media";
import ArticleAnimationRight from "./common/article_animation_right";
import OurWork from "./common/our_work";
import ArticleAnimationLeft from "./common/article_animation_left";
import Footer from "./common/footer";
import ListWorking from "./common/list_working";
import ArticleVideo from "./common/article_video";
import ArticleLogo from "./common/article_logo";
import { db } from "../firebase-config";
import { collection, onSnapshot, query, orderBy } from "@firebase/firestore";
import * as dataTable from "./constant_table";
import { TYPE_ARTICLE } from "./constant";
import ArticleService from "./common/article_service";
import SubmitForm from "./common/submit_form";
import ArticleTitle from "./common/article_title";
import ArticleVacancies from "./common/article_vacancies";

function CultureCareers() {
  document.title = "Culture & Careers - Target Media";
  const [dataHome, setDataHome] = useState([]);
  const [data, setData] = useState("");
  const [logo, setLogo] = useState("");

  useEffect(() => {
    getCompanyinfo();
  }, []);

  const getCompanyinfo = async () => {
    const dbColection = collection(db, dataTable.TABLE_COMPANY);
    await onSnapshot(dbColection, (result) => {
      const com = result.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0];
      setData(com);
      setLogo(com.logo);
    });

    const homeColection = query(
      collection(db, dataTable.TABLE_CULTURE_CAREERS),
      orderBy("index")
    );
    await onSnapshot(homeColection, (result) => {
      setDataHome(result.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
    return;
  };

  return (
    <section>
      <Header backgroundColor={data.color} imgLogo={logo} data={data} />

      {dataHome.map((item, index) => {
        if (item.type === TYPE_ARTICLE[0].value) {
          return (
            <ArticleMedia
              key={index}
              sub_title={item.sub_title}
              title={item.title}
              content={item.description}
              nameButon={item.button_name}
              imageUrl={item.url}
            />
          );
        } else if (item.type === TYPE_ARTICLE[1].value) {
          return <ArticleAnimationRight key={index} item={item} />;
        } else if (item.type === TYPE_ARTICLE[2].value) {
          return <ArticleAnimationLeft key={index} item={item} />;
        } else if (item.type === TYPE_ARTICLE[3].value) {
          return (
            <ArticleService
              title={item.title}
              key={index}
              keyCode={item.key_code}
            />
          );
        } else if (item.type === TYPE_ARTICLE[4].value) {
          return <ArticleLogo key={index} />;
        } else if (item.type === TYPE_ARTICLE[5].value) {
          return <ArticleVideo key={index} videomp4={item.url} />;
        } else if (item.type === TYPE_ARTICLE[6].value) {
          return (
            <ArticleTitle
              title={item.title}
              content={item.sub_title}
              key={index}
            />
          );
        } else if (item.type === TYPE_ARTICLE[7].value) {
          return (
            <SubmitForm
              key={index}
              backgroundColor={data.color}
              title={item.title}
              subTitle={item.sub_title}
            />
          );
        } else if (item.type === TYPE_ARTICLE[8].value) {
          return (
            <OurWork key={index} data={item} backgroundColor={data.color} />
          );
        } else if (item.type === TYPE_ARTICLE[9].value) {
          return <ListWorking backgroundColor={data.color} key={index} />;
        } else if (item.type === TYPE_ARTICLE[10].value) {
          return <ArticleVacancies data={item} key={index} />;
        }
        return <></>;
      })}
      <Footer backgroundColor={data.color} imgLogo={logo} data={data} />
    </section>
  );
}

export default CultureCareers;

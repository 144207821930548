import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";

function Footer({ backgroundColor, imgLogo, data = {} }) {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <footer className="g03" style={{ backgroundColor: backgroundColor }}>
      <div className="container container--large">
        <div className="g03__bottom">
          <a
            href="/"
            className="g03__logo"
            aria-label="Select to return to the top of the page"
          >
            <img
              src={imgLogo}
              alt={imgLogo}
              width="150"
              className="g03__logo-image"
            />
          </a>

          <div className="g03__copyright">
            <div style={{ fontSize: "1.3rem" }}>{data.company_name}</div>
            <div style={{ display: "flex" }}>
              <Icon.MapPin size={18} />
              <span style={{ marginLeft: "10px" }}>{data.address}</span>
            </div>
            <div>
              <a
                href={"mailto:" + data.email}
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  display: "flex",
                }}
              >
                <Icon.Mail size={18} />
                <span style={{ marginLeft: "10px" }}>{data.email}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {offset > 200 && (
        <button className="btn-top" title="Go to top" onClick={() => onTop()}>
          <div>
            <Icon.Triangle className="arrow-up-btn-top" size={7} />
          </div>
          <div className="btn-top-label">TOP</div>
        </button>
      )}
    </footer>
  );
}

export default Footer;

import React from "react";
import "./App.css";
import "./HomePage.css";
import "../node_modules/react-toastify/dist/ReactToastify.css";
import "../node_modules/react-toastify/dist/ReactToastify.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/home";
import Service from "./components/services";
import Project from "./components/projects";
import CultureCareers from "./components/culture_careers";
import AdminDashboard from "./components/Admin/dashboard";
import AdminServices from "./components/Admin/services";
import AdminProjects from "./components/Admin/projects";
import AdminCultureCareers from "./components/Admin/culture_careers";
import AdminUsers from "./components/Admin/users";
import AdminHome from "./components/Admin/home";
import Setting from "./components/Admin/setting";
import Login from "./components/Admin/login";
import ListLogo from "./components/Admin/list_logo";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import ListWorking from "./components/Admin/list_working";
import OurWork from "./components/Admin/our_work";
import TypeWorking from "./components/Admin/type_working";
import ListVacancies from "./components/Admin/list_vacancies";
import Details from "./components/detail";
import UploadImages from "./components/Admin/upload_images";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/services" element={<Service />} />
        <Route exact path="/projects" element={<Project />} />
        <Route exact path="/culture_careers" element={<CultureCareers />} />
        <Route exact path="/detail/:id" element={<Details />} />
        <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
        <Route exact path="/admin/services" element={<AdminServices />} />
        <Route exact path="/admin/projects" element={<AdminProjects />} />
        <Route exact path="/admin/list-logo" element={<ListLogo />} />
        <Route exact path="/admin/list-working" element={<ListWorking />} />
        <Route exact path="/admin/type-working" element={<TypeWorking />} />
        <Route exact path="/admin/list-our-work" element={<OurWork />} />
        <Route
          exact
          path="/admin/culture_careers"
          element={<AdminCultureCareers />}
        />
        <Route exact path="/admin/users" element={<AdminUsers />} />
        <Route exact path="/admin/home" element={<AdminHome />} />
        <Route exact path="/admin/setting" element={<Setting />} />
        <Route exact path="/admin/login" element={<Login />} />
        <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
        <Route exact path="/admin" element={<AdminDashboard />} />
        <Route exact path="/admin/vacancies" element={<ListVacancies />} />
        <Route exact path="/admin/upload-images" element={<UploadImages />} />
        <Route exact path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { toast } from "react-toastify";

toast.configure();

export default function SelectImage({ visible, onClose, onSave }) {
  const [data, setData] = useState([]);
  const [imgSelect, setImgSelect] = useState("");

  useEffect(() => {
    getData();
  }, [visible]);

  const getData = async () => {
    const res = await fetch("/file-images.php").then((response) =>
      response.json()
    );
    if (res.success) {
      setData(res.data || []);
    }
  };

  const onSelect = (value) => {
    setImgSelect("/" + value);
  };

  return (
    <Modal size="xl" isOpen={visible}>
      <ModalHeader>Select Image</ModalHeader>
      <ModalBody>
        {data.map((value, index) => {
          return (
            <div className="select-image-div-1" key={index}>
              <div
                className={
                  "/" + value === imgSelect
                    ? "bg-select-image-div-2 selected-image-gb"
                    : "bg-select-image-div-2"
                }
              >
                <img
                  src={"/" + value}
                  key={index}
                  className="select-image-bg"
                  onClick={() => onSelect(value)}
                  alt="/"
                />
              </div>
            </div>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-small btn-light"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
          <Button
            className="bg-gradient-primary"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => onSave(imgSelect)}
          >
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

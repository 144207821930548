import React, { useEffect, useState } from "react";
import { db } from "../../firebase-config";
import {
  collection,
  onSnapshot,
  query,
  where,
  limit,
} from "@firebase/firestore";
import * as dataTable from "../constant_table";
import parse from "html-react-parser";
import { TABLE_TYPE_VACANCIES } from "../constant";

function ArticleVacancies({ data = {} }) {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    const getQuery = query(
      collection(db, dataTable.TABLE_WORKING),
      where("is_visible", "==", true),
      where("table_type", "==", TABLE_TYPE_VACANCIES),
      limit(4)
    );

    await onSnapshot(getQuery, (result) => {
      setDatas(result.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
  };

  return (
    <article className="m18 break-out" id="vacancies">
      <div className="container m18__container">
        <h2 className="m18__heading heading--1 uppercase">{data.title}</h2>

        <div className="m18__introduction">
          <p>{data.sub_title}</p>
        </div>

        <div className="m18__vacancies">
          {datas.map((item, index) => {
            return (
              <div className="single-vacancy-item" key={index}>
                <a
                  href={`/detail/${item.id}`}
                  className="single-vacancy-item__link"
                  target=""
                >
                  <div className="single-vacancy-item__content">
                    <h3 className="single-vacancy-item__heading heading--4 uppercase">
                      {item.title}
                    </h3>
                    <div className="single-vacancy-item__description">
                      {parse(item.description)}
                      <span className="read-more">Read more...</span>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </article>
  );
}

export default ArticleVacancies;

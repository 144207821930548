import React, { useEffect, useState } from "react";
import { db } from "../../firebase-config";
import { collection, onSnapshot } from "@firebase/firestore";
import * as dataTable from "../constant_table";
import parse from "html-react-parser";

function OurWork({ data, backgroundColor }) {
  const colectionDb = collection(db, dataTable.TABLE_OUR_WORK);
  const [datas, setDatas] = useState([]);
  const [translate3d, setTranslate3] = useState(0);
  const styleTransform = {
    transform: `translate3d(-${translate3d}%, 0px, 0px)`,
    transition: `transform  .9s ease`,
  };

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    await onSnapshot(colectionDb, (result) => {
      setDatas(result.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
  };
  const onClickPrev = () => {
    let numTranslate3d = translate3d;
    const lengthItems = data.length;
    if (numTranslate3d === 0) {
      setTranslate3((lengthItems - 1) * 100);
    } else {
      setTranslate3(numTranslate3d - 100);
    }
  };

  const onClickNext = () => {
    let numTranslate3d = translate3d;
    const lengthItems = data.length;
    if (numTranslate3d === (lengthItems - 1) * 100) {
      setTranslate3(0);
    } else {
      setTranslate3(numTranslate3d + 100);
    }
  };

  return (
    <article className="wp-block-luna-m06-case-studies m06 break-out">
      <div className="container m06__introduction">
        <h2 className="m06__heading uppercase heading--1">{data.title}</h2>
      </div>
      <div className="m06__carousel">
        <div
          className="m06__content"
          style={{ backgroundColor: backgroundColor }}
        >
          <div className="m06__wrapper m06__wrapper--content">
            <div className="m06__container" style={styleTransform}>
              {datas.map((item, index) => {
                return (
                  <article
                    className="m06__slide is-selected"
                    style={{ left: "0%" }}
                    key={index}
                  >
                    <figure className="m06__mobile-media">
                      <picture>
                        <source
                          srcSet={item.large_image}
                          media="(min-width: 768px)"
                        />
                        <source
                          srcSet={item.medium_image}
                          media="(min-width: 375px)"
                        />
                        <img
                          loading="lazy"
                          src={item.minium_image}
                          alt=""
                          width="1188"
                          height="668"
                          className="m06__slide-image"
                        />
                      </picture>
                      <a href={item.link} className="m06__media-link">
                        <span className="screen-reader-text">
                          See Case Study
                        </span>
                      </a>
                    </figure>
                    <div className="m06__slide-content">
                      <h3 className="m06__slide-heading uppercase heading--3">
                        <a
                          href={item.link}
                          className="m06__slide-heading__link"
                          style={{ fontWeight: "bold" }}
                        >
                          {item.title}
                        </a>
                      </h3>
                      <p className="m06__slide-excerpt"></p>
                      <p>{parse(item.description)}</p>
                      <a href={item.link} className="m06__slide-link">
                        See Case Study
                      </a>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
          {datas.length > 1 && (
            <div className="m06__controls">
              <button
                className="button button--white m06__nav m06__nav--prev"
                aria-label="Select to go to the previous slide"
                onClick={() => onClickPrev()}
              >
                <svg
                  className="svg-icon svg-icon--icon_arrow"
                  role="img"
                  aria-hidden="true"
                >
                  <use href="#sprite-icon_arrow"></use>
                </svg>
                <span className="m06__nav-label">Prev</span>
              </button>

              <button
                className="button button--white m06__nav m06__nav--next"
                aria-label="Select to go to the next slide"
                onClick={() => onClickNext()}
              >
                <span className="m06__nav-label">Next</span>
                <svg
                  className="svg-icon svg-icon--icon_arrow"
                  role="img"
                  aria-hidden="true"
                >
                  <use href="#sprite-icon_arrow"></use>
                </svg>
              </button>
            </div>
          )}
        </div>
        <div className="m06__media">
          <div className="m06__wrapper m06__wrapper--media">
            <div className="m06__container" style={styleTransform}>
              {datas.map((item, index) => {
                return (
                  <article
                    className="m06__slide is-selected"
                    style={{ left: "0%" }}
                    key={index}
                  >
                    <figure className="m06__slide-media">
                      <picture>
                        <source
                          srcSet={item.large_image}
                          media="(min-width: 768px)"
                        />
                        <source
                          srcSet={item.medium_image}
                          media="(min-width: 375px)"
                        />
                        <img
                          loading="lazy"
                          src={item.minium_image}
                          alt=""
                          width="1188"
                          height="668"
                          className="m06__slide-image"
                        />
                      </picture>
                      <a href={item.link} className="m06__media-link">
                        <span className="screen-reader-text">
                          See Case Study
                        </span>
                      </a>
                    </figure>
                  </article>
                );
              })}
            </div>
          </div>
          {/* <div className="m06__dots">
            <button
              type="button"
              data-dot="0"
              className="m06__dot is-selected"
            ></button>
            <button type="button" data-dot="1" className="m06__dot"></button>
            <button type="button" data-dot="2" className="m06__dot"></button>
            <button type="button" data-dot="3" className="m06__dot"></button>
            <button type="button" data-dot="4" className="m06__dot"></button>
            <button type="button" data-dot="5" className="m06__dot"></button>
          </div> */}
        </div>
      </div>
    </article>
  );
}

export default OurWork;

import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC5CeBp0q3cja9sK5kXxlZ3miwL26957ck",
  authDomain: "target-media-1b31f.firebaseapp.com",
  projectId: "target-media-1b31f",
  storageBucket: "target-media-1b31f.appspot.com",
  messagingSenderId: "491816593205",
  appId: "1:491816593205:web:90e4ddc2aef38c5a338e7c",
  measurementId: "G-JFQ26T9ZBG",
};
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
